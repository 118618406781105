<template>
  <div>
    <Html :lang="actualLocale" :dir="head.htmlAttrs.dir">
      <template v-for="link in headLinks" :key="link.id">
        <Link
          :id="link.id"
          :rel="link.rel"
          :href="link.href"
          :hreflang="link.hreflang"
        />
      </template>
      <template v-for="meta in head.meta" :key="meta.id">
        <Meta :id="meta.id" :property="meta.property" :content="meta.content" />
      </template>
      <slot />
      <WidgetsWhatsappLink v-if="showWhatsapp" />
    </Html>
  </div>
</template>

<script setup>
  import { localeMappings } from '~/constants/locales';

  const { locale } = useI18n();
  const showWhatsapp = useLaunchDarkly('mx-whatsapp');
  const actualLocale = localeMappings[locale.value];

  const head = useLocaleHead({
    addDirAttribute: true,
    identifierAttribute: 'id',
    addSeoAttributes: true,
  });

  const headLinks = computed(() => {
    const i18nHeadFiltered = head.value.link
      ? head.value.link.filter((link) => {
          return link.rel === 'canonical' ||
            link.hreflang === 'en' ||
            link.hreflang === 'x-default'
            ? link
            : '';
        })
      : null;

    return [...i18nHeadFiltered];
  });
</script>
